*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

/* LOCOMOTIVE SCROLLING */
html.has-scroll-smooth {
  overflow: hidden; }

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.has-scroll-smooth body {
  overflow: hidden; }

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh; }

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap; }

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%; }

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0; }
  .c-scrollbar:hover {
    transform: scaleX(1.45); }
  .c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
    opacity: 1; }
  [data-scroll-direction="horizontal"] .c-scrollbar {
    width: 100%;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: scaleY(1); }
    [data-scroll-direction="horizontal"] .c-scrollbar:hover {
      transform: scaleY(1.3); }

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab; }
  .has-scroll-dragging .c-scrollbar_thumb {
    cursor: -webkit-grabbing;
    cursor: grabbing; }
  [data-scroll-direction="horizontal"] .c-scrollbar_thumb {
    right: auto;
    bottom: 0; }

.blur-bg{
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.dark{
  background-color: rgba(34, 34, 34, 0.5);
}

.light{
  background-color: rgba(255, 255, 255, 0.02);
}

@media screen and (max-width: 563px) {
  .responsive-landing-overview-bg{
    background-position: left -800px top;
  }  
  .responsive-services-overview-bg{
    background-position: left -200px top;
  }
  .responsive-projects-overview-bg{
    background-position: left -400px top;
  }
}
@media screen and (min-width:563px) and (max-width: 765px) {
  .responsive-landing-overview-bg{
    background-position: left -600px top;
  }  
  .responsive-projects-overview-bg{
    background-position: left -250px top;
  }
}
@media screen and (min-width:765px) and (max-width: 920px) {
  .responsive-landing-overview-bg{
    background-position: left -400px top;
  }  
}


.reviews-list::-webkit-scrollbar, .auth-card::-webkit-scrollbar{
  display: none;
}
.reviews-list, .auth-card{
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

/* .lazy-bg {
  width: 100%;
  height: 100vh;
  background-color: black;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.lazy-bg.bg-loaded {
  opacity: 1;
} */
